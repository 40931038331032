import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/vitor-pedroso.jpeg";
  const fullName = `Vitor Pedroso`;
  const zoomUrl = ""; // "https://us02web.zoom.us/j/86946126681?pwd=eWRScEtsdGsvRFZaclQ3c1k0R0ZSUT09";
  const zoomPhoneNumber = ""; // "(647) 374-4685";
  const zoomMeetingId = ""; //"869 461 26681";
  const zoomMeetingPasscode = ""; // "498110";
  const birthDate = ""; // new Date(1980, 1 - 1, 1);
  const deathDate = ""; // new Date(2020, 12 - 1, 31);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 25);
  const memorialTime = "11AM EST";
  const inviteText = ``;
  const inviteChild = ``;
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Vitor%20Pedroso%20-%20Recording.mp4",
      poster: "/images/people/vitor-pedroso-poster.png",
      title: "Viewing & Service Recording",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="2fr 5fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    ></StoryPage>
  );
};

export default ThisPage;
